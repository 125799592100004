import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { NgKLoggerService } from '@kin/ngk-logger';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideSparkles } from '@ng-icons/lucide';
import { PolicyStore } from 'app/common/services/legacy/policies/policies.store';
import { InlineLoadingSpinnerComponent } from 'app/create-quote/quote-review/components/inline-loading-spinner.component';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-footer-review',
  standalone: true,
  imports: [NgIconComponent, InlineLoadingSpinnerComponent, CurrencyPipe],
  templateUrl: './footer-review.component.html',
  styles: [
    `
      :host {
        box-shadow: var(--shadow-md);
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideIcons({ lucideSparkles })],
})
export class FooterReviewComponent {
  private logger = inject(NgKLoggerService);
  readonly policyStore = inject(PolicyStore);

  public policy = this.policyStore.data;
  public redirectTo = computed(() => this.policy().redirectTo ?? '/resume');

  public isLoading = computed(() => this.policyStore.status() === 'loading');
  public getPolicyPremiumSignal() {
    const policyPremiumSignal = this.policy().premium;
    if (policyPremiumSignal) {
      return policyPremiumSignal;
    }
    this.logger.error({ message: `Could not find policy premium signal`, context: 'FooterReviewComponent', priority: 'P3' });
    return undefined;
  }

  public getPolicyAddressSignal() {
    const policyAddressSignal = this.policy().address;
    if (policyAddressSignal) {
      return policyAddressSignal;
    }
    this.logger.error({ message: `Could not find policy address signal`, context: 'FooterReviewComponent', priority: 'P3' });
    return undefined;
  }

  public handlePrintOrSaveQuote() {
    window.print();
  }
}
