import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { QuoteDraftStore } from '../../../global-store/quote-draft';
import { FooterReviewComponent } from './footer-review/footer-review.component';
import { AppLoaderService } from '../../../app-global-loader/service/app-loader.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [FooterReviewComponent, AsyncPipe],
  templateUrl: './footer.component.html',
  styles: [
    `
      :host {
        box-shadow: var(--shadow-md);
        width: 100%;
        z-index: 1001; // prevent kin-dropdowns from being hidden behind footer
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @Input() currentRoute!: string;

  private loaderService = inject(AppLoaderService);
  readonly store = inject(QuoteDraftStore);
  public displayAddressSignal = this.store.displayAddress;
  public isLoadingActive$ = this.loaderService.isActive$;

  public isReviewPage() {
    if (this.currentRoute === '/quote/review') {
      return true;
    }
    return false;
  }
}
