<footer class="flex bg-neutral-white print:hidden" data-testid="footer-component">
  @if (isReviewPage()) {
    <app-footer-review></app-footer-review>
  } @else {
    <div class="container flex flex-wrap items-center gap-300 p-300">
      @if (!!displayAddressSignal()) {
        <div class="display-address mx-auto flex flex-nowrap items-center sm:mx-2">
          <svg class="hidden sm:block" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 10.5005C2.99993 10.2095 3.06333 9.92208 3.18579 9.65817C3.30824 9.39427 3.4868 9.16025 3.709 8.97246L10.709 2.97346C11.07 2.66836 11.5274 2.50098 12 2.50098C12.4726 2.50098 12.93 2.66836 13.291 2.97346L20.291 8.97246C20.5132 9.16025 20.6918 9.39427 20.8142 9.65817C20.9367 9.92208 21.0001 10.2095 21 10.5005V19.5005C21 20.0309 20.7893 20.5396 20.4142 20.9147C20.0391 21.2897 19.5304 21.5005 19 21.5005H5C4.46957 21.5005 3.96086 21.2897 3.58579 20.9147C3.21071 20.5396 3 20.0309 3 19.5005V10.5005Z"
              fill="var(--color-primary)"
              stroke="var(--color-neutral-800)"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15 20.5V13.3889C15 13.1531 14.8946 12.927 14.7071 12.7603C14.5196 12.5937 14.2652 12.5 14 12.5H10C9.73478 12.5 9.48043 12.5937 9.29289 12.7603C9.10536 12.927 9 13.1531 9 13.3889V20.5"
              fill="var(--color-primary)"
            />
            <path
              d="M15 20.5V13.3889C15 13.1531 14.8946 12.927 14.7071 12.7603C14.5196 12.5937 14.2652 12.5 14 12.5H10C9.73478 12.5 9.48043 12.5937 9.29289 12.7603C9.10536 12.927 9 13.1531 9 13.3889V20.5"
              stroke="var(--color-neutral-800)"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span class="ml-2" data-testid="footer-address">{{ displayAddressSignal() }}</span>
        </div>
      }

      @if ((isLoadingActive$ | async) === false) {
        <button data-testid="submit-button" form="quote-form" type="submit" class="button button--md button--primary button--block m-l-auto w-full sm:w-auto">Continue</button>
      }
    </div>
  }
</footer>
