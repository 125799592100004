import { Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, inject } from '@angular/core';

import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { AppConfigModule, AppContext } from '../../../app-config';
import { QuoteFormMappingKey, quoteFormMappingMap } from '../../../create-quote/form-config/quote-form.config';

@Component({
  selector: 'app-debug-nav-to-form',
  standalone: true,
  imports: [AppConfigModule, ReactiveFormsModule],
  template: `
    @if (notProd) {
      <div class="card">
        <label class="select">
          <span class="select__label">Navigate To a Form:</span>
          <select class="select__control" [formControl]="navToFormControl">
            @for (formName of formList; track formName) {
              <option [value]="formName">{{ formName }}</option>
            }
          </select>
        </label>
        <button (click)="navToForm()" [disabled]="!this.navToFormControl.valid" class="button button--secondary button--sm mt-100">Go to form</button>
      </div>
    }
  `,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugNavToFormComponent {
  private router = inject(Router);

  @AppContext() private _appContext!: AppContext;
  public notProd = this._appContext.notProd;

  // data sources
  public formList = Array.from(quoteFormMappingMap.keys());

  // controls
  public navToFormControl = new FormControl<QuoteFormMappingKey | undefined>(undefined, Validators.required);

  public navToForm() {
    if (this.navToFormControl.valid) {
      this.router.navigate([`/quote/${this.navToFormControl.value}`]);
    }
  }
}
