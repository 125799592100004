import { QuoteFormOptionsState } from 'app/global-store/quote-form-options/quote-form-options.model';

export const mockFormOptions: QuoteFormOptionsState = {
  'about-you': undefined,
  'policy-type': [
    {
      label: 'Homeowners',
      value: 'homeowners',
      id: 'homeowners',
      description: 'Applies if you own and live in a single-family or duplex home.',
      icon: 'home',
    },
    {
      label: 'Condo',
      value: 'condominium',
      id: 'condominium',
      description: 'Applies if an HOA or condo insures part of your home unit, or if you own and rent out a condo.',
      icon: 'condo',
    },
    {
      label: 'Manufactured home',
      value: 'mobile_home',
      id: 'mobile_home',
      description: 'Applies if you own and live in or own and rent out a manufactured or mobile home.',
      icon: 'manufactured_home',
    },
    {
      label: 'Landlord',
      value: 'landlord',
      id: 'landlord',
      description: 'Applies if you own and rent out a detached home or townhouse.',
      icon: 'landlord',
    },
  ],
  'unit-number-check': undefined,
  'unit-number-entry': undefined,
  'insurance-status': [
    {
      id: 'currently_insured',
      label: "I'm looking to switch insurance providers",
      icon: 'lucideClock',
      value: 'currently_insured',
    },
    {
      id: 'new_purchase_new_policy',
      label: "I'm buying a home, but it's not my first time.",
      icon: 'lucideClock',
      value: 'new_purchase_new_policy',
    },
    {
      id: 'no_insurance',
      label: 'My previous coverage is expired, cancelled, or non-renewed.',
      icon: 'lucideClock',
      value: 'no_insurance',
    },
    {
      id: 'new_purchase_new_policy_first_time',
      label: "I'm buying a home for the first time.",
      icon: 'lucideClock',
      value: 'new_purchase_new_policy_first_time',
    },
  ],
  'rental-status': undefined,
  'rental-number': [],
  residency: [
    {
      id: '12',
      value: '12',
      label: 'Yes, I live here more than half of the year',
    },
    {
      id: '6',
      value: '6',
      label: 'No, I live here less than half of the year',
    },
    {
      id: '0',
      value: '0',
      label: 'No, the house is vacant',
    },
  ],
  'policy-start-date': undefined,
  'co-applicant': undefined,
  'co-applicant-info': undefined,
  'property-type': [
    {
      label: 'Single-family detached',
      value: 'single_family_detached',
      id: 'single_family_detached',
      description: 'A home, built on a single lot with no shared walls',
      icon: 'home',
    },
    {
      label: 'Townhouse',
      value: 'townhouse',
      id: 'townhouse',
      description: 'Single units in a small community with multiple owners, often having multiple floors and at least one shared wall between homes',
      icon: 'townhouse',
    },
    {
      label: 'Manufactured/mobile home',
      value: 'manufactured_mobile_home',
      id: 'manufactured_mobile_home',
      description: 'Factory-built homes that are moved to a location and usually kept there permanently',
      icon: 'manufactured_home',
    },
    {
      label: 'Condo',
      value: 'condominium',
      id: 'condominium',
      description: 'Single units with multiple owners within a larger building or community, usually part of an association that also shares a wall (or more than one) with at least one other unit',
      icon: 'condo',
    },
    {
      label: 'Commercial',
      value: 'commercial',
      id: 'commercial',
      description: 'Real estate used for business activities, including large residential rental properties',
      icon: 'commercial',
    },
    {
      label: 'Multi-family',
      value: 'multi_family_unknown_num_of_units',
      id: 'multi_family_unknown_num_of_units',
      description: 'Single building with one owner, designed to house multiple families living separately',
      icon: 'multi_family',
    },
    {
      label: 'Land',
      value: 'land',
      id: 'land',
      description: 'A lot or land with no built structure to insure',
      icon: 'land',
    },
  ],
  'roof-shape': [
    {
      id: 'hip',
      label: 'Hip',
      value: 'hip',
      description: 'Has slopes on all 4 sides that come together at the top to form a ridge',
    },
    {
      id: 'flat',
      label: 'Flat',
      value: 'flat',
      description: 'Has nearly no pitch and allows for extra use of space, like a rooftop garden',
    },
    {
      id: 'other',
      label: 'Other',
      value: 'other',
      description: "Choose this option if your roof's shape is not listed here ",
    },
  ],
  'roof-year': undefined,
  'year-built': [
    {
      id: 'sq_ft',
      label: 'What is the number of livable square feet?',
    },
    {
      id: 'stories',
      label: 'How many stories is your home?',
    },
    {
      id: 'pool',
      label: 'Does your home have a pool?',
    },
    {
      id: 'year_built',
      label: 'What year was this home built?',
    },
  ],
  'construction-type': [
    {
      label: 'Frame',
      value: 'frame',
      id: 'frame',
      description: 'Built with lumber, but the exterior wall surface may be covered with wood, brick, decorative veneers or stucco',
      image: 'frame.png',
    },
    {
      label: 'Masonry',
      value: 'masonry',
      id: 'masonry',
      description: 'Consists of brick and stone structures put together with mortar',
      image: 'masonry.png',
    },
    {
      label: 'Log',
      value: 'log',
      id: 'log',
      description: 'Consists of stacked logs where the same wood is seen from the inside and the outside',
      image: 'log.png',
    },
    {
      label: 'Superior fire-resistive',
      value: 'superior_fire_resistive',
      id: 'superior_fire_resistive',
      description: 'Made of materials that can withstand high heat and flame, such as concrete or cinder blocks',
      image: 'superior_fire_resistive.png',
    },
  ],
  'exterior-wall': [
    {
      label: 'Brick',
      description: 'The exterior is made of solid bricks or brick veneer',
      image: 'brick.png',
      id: 'brick_solid',
      value: 'brick_solid',
    },
    {
      label: 'Stucco',
      description: 'This is a type of cement made to look like plaster',
      image: 'stucco.png',
      id: 'traditional_stucco',
      value: 'traditional_stucco',
    },
    {
      label: 'Vinyl',
      description: 'This is a durable form of plastic siding',
      image: 'vinyl.png',
      id: 'siding_vinyl',
      value: 'siding_vinyl',
    },
    {
      label: 'Cement Fiber',
      description: 'Siding made from fiber cement (mix of wood pulp and cement), which is also known as hardie board',
      image: 'cement.png',
      id: 'siding_cement_fiber_clapboard',
      value: 'siding_cement_fiber_clapboard',
    },
    {
      label: 'Wood',
      description: 'Usually made from wood shakes or shingles, plywood, or pine clapboard',
      image: 'wood.png',
      id: 'wood_shingle_shake',
      value: 'wood_shingle_shake',
    },
    {
      label: 'Stone',
      description: "The home's exterior is made of solid stones or stone veneer",
      image: 'stone.png',
      id: 'stone_veneer_natural',
      value: 'stone_veneer_natural',
    },
    {
      label: 'Metal',
      description: 'Typically made of aluminum siding, but can also be steel',
      image: 'metal.png',
      id: 'metal_corrugated_galvanized',
      value: 'metal_corrugated_galvanized',
    },
  ],
  'property-information': [
    {
      id: 'within_park',
      label: 'Within park',
    },
    {
      id: 'bathrooms',
      label: 'Bathrooms',
    },
    {
      id: 'roof_solar_panels',
      label: 'Solar Panels',
    },
    {
      id: 'acreage',
      label: 'Acreage',
    },
    {
      id: 'purchase_date',
      label: 'Purchase Date',
    },
    {
      id: 'roof_type',
      label: 'Roof Type',
      selectOptions: [
        {
          label: 'Asphalt',
          value: 'asphalt',
        },
        {
          label: 'Cedar',
          value: 'cedar',
        },
      ],
    },
  ],
  foundation: [
    {
      label: 'Slab',
      value: 'slab',
      id: 'slab',
      description: 'Your home sits directly on the foundation, with no space like a crawlspace or basement underneath.',
      icon: 'slab.svg',
    },
    {
      label: 'Crawlspace',
      value: 'crawlspace',
      id: 'crawlspace',
      description: 'Your foundation has a small space, typically 3-4 feet high, between the ground and your home.',
      icon: 'crawl-space.svg',
    },
    {
      label: 'Elevated Piers/Pilings',
      value: 'elevated_piers_pilings',
      id: 'elevated_piers_pilings',
      description: 'Your home is raised on piers or pilings, often with access to electrical and plumbing underneath.',
      icon: 'elevated-piers.svg',
    },
    {
      label: 'Basement',
      value: 'partially_finished_basement',
      id: 'partially_finished_basement',
      description: "Choose this option if your home has a basement, whether it's finished or unfinished.",
      icon: 'basement.svg',
    },
    {
      label: 'Elevated With Enclosure',
      value: 'elevated_with_enclosure',
      id: 'elevated_with_enclosure',
      description: "Your home is elevated, and there's an enclosed space underneath, like a garage.",
      icon: 'elevated-enclosure.svg',
    },
  ],
};
