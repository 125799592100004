import { CommonModule } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, input } from '@angular/core';
import '@kin/web-components/dist/external/kin-loading-indicator/kin-loading-indicator';
import { FadeInOut, FadeOut } from './fade.animation';
import { TrustpilotComponent } from './trustpilot/trustpilot.component';

/**
 * Loading Overlay
 * Presentation component that overlays the container it is in with a loader
 *
 * This is used in the app loader, but can be used on its own as well
 */
@Component({
  selector: 'app-loading-overlay',
  standalone: true,
  imports: [CommonModule, TrustpilotComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [FadeInOut(), FadeOut()],
  template: `
    @if (isOn$ | async) {
      <!-- background - hides the content in the container -->
      <div [@fadeOut] class="absolute left-0 top-0 h-full w-full bg-white"></div>
      <!-- loader - follows the user so they see if if they are too far down the page -->
      <div [@fadeInOut] class="fixed left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 transform p-800 md:w-auto">
        <div class="ta-center md:max-w-2xl">
          @if (titleSignal(); as title) {
            <div class="flex flex-wrap items-center justify-center pb-600">
              <h1 data-test="loader-title" class="h3 md:h2">{{ title }}</h1>
              @if (messageSignal(); as message) {
                <p data-test="loader-message" class="block max-w-lg items-center justify-center pt-200 text-left">{{ message }}</p>
              }
            </div>
          }
          <kin-loading-indicator class="block"></kin-loading-indicator>
          @if (showTrustPilotSignal()) {
            <app-trustpilot [@fadeInOut] class="block pt-300 md:pt-800"></app-trustpilot>
          }
        </div>
      </div>
    }
  `,
})
export class LoadingOverlayComponent {
  private _isOn$ = new BehaviorSubject(false);

  /**
   * Indicates if the loader is on or off
   * true = on
   */
  readonly isOn$ = this._isOn$.asObservable();

  /**
   * Optional title
   * presents as a header at the top.
   */
  public titleSignal = input<string | undefined>(undefined, { alias: 'title' });

  /**
   * Optional message
   * presents under title in smaller text.
   */
  public messageSignal = input<string | undefined>(undefined, { alias: 'message' });

  /**
   * Shows Trustpilot Widget
   */
  public showTrustPilotSignal = input(false, { alias: 'showTrustPilot' });

  /**
   * Turns the loader "On" or activates it
   */
  public on() {
    this._isOn$.next(true);
  }

  /**
   * Turns the loader "Off" or de-activates it
   */
  public off() {
    this._isOn$.next(false);
  }
}
