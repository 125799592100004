import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucidePhone } from '@ng-icons/lucide';
import { ContactCardComponent } from '../../../error-pages/contact-card/contact-card.component';
import '@kin/web-components/dist/external/kin-dialog/kin-dialog';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [NgIconComponent, ContactCardComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  providers: [provideIcons({ lucidePhone })],
})
export class HeaderComponent {}
